'use strict';

angular.module('doctors.licensing.ctrl', ['forms.doctors.licensing.dir'])

.controller('DoctorLicensingCtrl', ['$q', '$scope', 'Restangular', 'DoctorCredentialingSections', 'CurrentDoctor', function($q, $scope, Restangular, DoctorCredentialingSections, CurrentDoctor) {
  var ctrl = this;

  ctrl.removedStateLiceneses = [];

  Restangular.one('doctor-credentialing-sections', 2).get().then(function(section) {
    ctrl.section = section;
  });

  CurrentDoctor.then(function(doctor) {
    ctrl.doctor = doctor;
    return $q.all([
      ctrl.doctor.one('status', 2).get(),
      ctrl.doctor.one('dea-license').get(),
      ctrl.doctor.one('board-certification').get(),
      ctrl.doctor.all('state-licenses').getList()
    ]).then(function(data) {
      ctrl.status = data[0];
      ctrl.dea = data[1];
      ctrl.boardCertification = data[2];
      ctrl.stateLicenses = data[3];
      ctrl.isLoaded = true;
    });
  }).finally(function() {
    ctrl.isLoaded = true;
  });

  ctrl.save = function() {
    ctrl.errors = null;

    var promises = [
      ctrl.dea.patch(),
      ctrl.boardCertification.patch()
    ];

    ctrl.stateLicenses.forEach(function(stateLicense) {
      promises.push(stateLicense.id ? stateLicense.patch() : ctrl.stateLicenses.post(stateLicense));
    });

    ctrl.removedStateLiceneses.forEach(function(stateLicense) {
      if (!stateLicense.id) return;
      promises.push(stateLicense.remove());
    });

    return $q.all(promises).catch(function(res) {
      return $q.reject(res.data.errors);
    });
  };

  $scope.$on('forms.doctors.licensing.didRemoveStateLicense', function(e, stateLicense) {
    ctrl.removedStateLiceneses.push(stateLicense);
  });

  ctrl.saveAndSubmit = DoctorCredentialingSections.saveAndSubmit.bind(this);
  ctrl.saveForLater = DoctorCredentialingSections.saveForLater.bind(this);
}]);
