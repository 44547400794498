'use strict';

angular.module('select-state.dir', ['restangular'])

.directive('selectState', [function() {
  return {
    restrict: 'E',
    scope: false,
    replace: true,
    template: '<select ng-options="state.abbr as state.name for state in states"></select>',
    controller: ['$scope', 'Restangular', function ($scope, Restangular) {
      $scope.states = [];
      Restangular.all('states').getList().then(function(states) {
        $scope.states = states;
      });
    }]
  };
}]);
