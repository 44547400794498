'use strict';

angular.module('doctor-credentialing-sections.srv', ['url-title.fltr'])

.factory('DoctorCredentialingSections', ['$q', '$location', 'Restangular', 'urlTitleFilter', function($q, $location, Restangular, urlTitleFilter) {
  var getStatusForDoctor = function(doctor) {
    return $q.all([
      Restangular.all('doctor-credentialing-section-groups').getList(),
      doctor.all('doctor-credentialing-sections').getList(),
      doctor.one('status').get()
    ]).then(function(data) {
      var credentialingSectionGroups = data[0];
      var credentialingSections = data[1];
      var doctorStatus = data[2];

      var sectionsGroupedByGroupId = credentialingSections.reduce(function(groups, section) {
        if (!groups[section.doctor_credentialing_section_group_id]) {
          groups[section.doctor_credentialing_section_group_id] = [];
        }
        groups[section.doctor_credentialing_section_group_id].push(section);
        return groups;
      }, {});

      var doctorStatusBySectionId = doctorStatus.doctor_credentialing_sections.reduce(function(status, section) {
        status[section.doctor_credentialing_section_id] = section.doctor_credentialing_status;
        return status;
      }, {});

      var groups = credentialingSectionGroups.filter(function(group) {
        return sectionsGroupedByGroupId[group.id] != null;
      }).map(function(group) {
        group.sections = sectionsGroupedByGroupId[group.id].map(function(section) {
          section.status = doctorStatusBySectionId[section.id];
          return section;
        });

        return group;
      });

      return {
        current: doctorStatus.doctor_credentialing_status,
        status_changed: doctorStatus.status_changed,
        last_approved: doctorStatus.last_approved,
        groups: groups
      };
    });
  };

  var submitDoctorForReview = function(doctor) {
    return doctor.one('status').get().then(function(status) {
      var promises = [];
      status.doctor_credentialing_sections.filter(function(section) {
        return section.doctor_credentialing_status.id != 1;
      }).forEach(function(section) {
        promises.push(doctor.one('status').post(section.doctor_credentialing_section_id, {
          doctor_credentialing_status_id: 2
        }));
      });
      return $q.all(promises);
    });
  };

  var saveAndSubmit = function() {
    var ctrl = this;
    ctrl.isWaiting = true;
    ctrl.save().then(function() {
      return ctrl.status.post(ctrl.status.section_id, {
        doctor_credentialing_status_id: 3
      }).catch(function(res) {
        return $q.reject(res.data.errors);
      });
    }, function(errors) {
      return ctrl.status.get().then(function(status) {
        return $q.reject(angular.extend(errors, status.errors || {}));
      });
    }).then(function() {
      next(ctrl.doctor, 4, ctrl.status.section_id);
    }).catch(function(errors) {
      ctrl.errors = errors;
    }).finally(function() {
      ctrl.isWaiting = false;
    });
  };

  var saveForLater = function() {
    var ctrl = this;
    ctrl.isWaiting = true;
    ctrl.save().then(function() {
      home();
    }, function(errors) {
      ctrl.errors = errors;
    }).finally(function() {
      ctrl.isWaiting = false;
    });
  };

  var home = function() {
    $location.path('/doctors/credentialing');
  };

  var next = function(doctor, withStatusId, afterSectionId) {
    getStatusForDoctor(doctor).then(function(status) {
      var nextSection = findNextSection(status, withStatusId, afterSectionId);

      if (nextSection) {
        $location.path('/doctors/' + urlTitleFilter(nextSection.name));
      } else {
        home();
      }
    });
  };

  var findNextSection = function(currentStatus, withStatusId, afterSectionId) {
    var reachedAfterSection = afterSectionId ? false : true;
    var nextSection;

    for (var i = 0; i < currentStatus.groups.length; i++) {
      for (var j = 0; j < currentStatus.groups[i].sections.length; j++) {
        var section = currentStatus.groups[i].sections[j];
        if (reachedAfterSection) {
          if (section.status.id === withStatusId) {
            nextSection = section;
            break;
          }
        } else {
          reachedAfterSection = section.id == afterSectionId;
        }
      }

      if (nextSection) {
        break;
      }
    }

    return nextSection;
  };

  return {
    getStatusForDoctor: getStatusForDoctor,
    submitDoctorForReview: submitDoctorForReview,
    home: home,
    next: next,
    findNextSection: findNextSection,
    saveForLater: saveForLater,
    saveAndSubmit: saveAndSubmit
  };
}]);
