'use strict';

angular.module('doctors.languages.ctrl', ['forms.doctors.languages.dir'])

.controller('DoctorLanguagesCtrl', ['$q', '$scope', 'DoctorCredentialingSections', 'CurrentDoctor', 'Restangular', function($q, $scope, DoctorCredentialingSections, CurrentDoctor, Restangular) {
  var ctrl = this;

  // language: sortIndex
  var defaultLanguages = {
    'English': 0,
    'Spanish': 1
  };
  ctrl.languages = [];
  ctrl.removedLanguages = [];

  Restangular.one('doctor-credentialing-sections', 22).get().then(function(section) {
    ctrl.section = section;
  });

  CurrentDoctor.then(function(doctor) {
    ctrl.doctor = doctor;
    return $q.all([
      doctor.one('status', 22).get(),
      doctor.all('languages').getList()
    ]);
  }).then(function(data) {
    ctrl.status = data[0];

    var languageCollection = data[1];
    var languages = [];
    var otherLanguages = [];

    languageCollection.forEach(function(language) {
      if (language.name in defaultLanguages) {
        var sortIndex = defaultLanguages[language.name];
        language.isDefaultLanguage = true;
        languages[sortIndex] = language;
      } else {
        otherLanguages.push(language);
      }
    });

    angular.forEach(defaultLanguages, function(sortIndex, languageName) {
      if (!languages[sortIndex]) {
        languages[sortIndex] = {
          name: languageName,
          isDefaultLanguage: true
        };
      }
    });

    languageCollection.splice(0, languageCollection.length);
    languageCollection.push.apply(languageCollection, languages);
    languageCollection.push.apply(languageCollection, otherLanguages);
    ctrl.languages = languageCollection;

    ctrl.isLoaded = true;
  });

  ctrl.save = function () {
    ctrl.errors = null;

    var promises = [];

    ctrl.languages.forEach(function(language) {
      promises.push(language.id ? language.patch() : ctrl.languages.post(language));
    });

    ctrl.removedLanguages.forEach(function(language) {
      if (!language.id) return;
      promises.push(language.remove());
    });

    return $q.all(promises).catch(function(res) {
      return $q.reject(res.data.errors);
    });
  };

  $scope.$on('forms.doctors.languages.didRemoveLanguage', function(e, language) {
    ctrl.removedLanguages.push(language);
  });

  ctrl.saveAndSubmit = DoctorCredentialingSections.saveAndSubmit.bind(this);
  ctrl.saveForLater = DoctorCredentialingSections.saveForLater.bind(this);
}]);
