'use strict';

angular.module('forms.doctors.requirements-for-participation.dir', [])

.directive('formDoctorRequirementsForParticipation', [function() {
  return {
    restrict: 'E',
    scope: {
      ipa: '=',
      requirements: '=',
      signature: '=',
      errors: '='
    },
    replace: true,
    templateUrl: '/forms/doctors/requirements-for-participation/requirements-for-participation.html',
    controller: [function() {}],
    controllerAs: 'ctrl',
    bindToController: true
  };
}]);
