'use strict';

angular.module('doctors.educational-verification.ctrl', ['forms.doctors.educational-verification.dir'])

.controller('DoctorEducationalVerificationCtrl', ['$scope', '$q', 'DoctorCredentialingSections', 'CurrentDoctor', 'Restangular', function($scope, $q, DoctorCredentialingSections, CurrentDoctor, Restangular) {
  var ctrl = this;

  Restangular.one('doctor-credentialing-sections', 20).get().then(function(section) {
    ctrl.section = section;
  });

  CurrentDoctor.then(function(doctor) {
    ctrl.doctor = doctor;
    return $q.all([
      doctor.one('status', 20).get(),
      Restangular.one('ipas', doctor.ipa_id).get(),
      getEducationalDocument()
    ]);
  }).then(function(data) {
    ctrl.status = data[0];
    ctrl.ipa = data[1];
    ctrl.document = data[2];
    ctrl.isLoaded = true;
  });

  var getEducationalDocument = function() {
    return ctrl.doctor.getList('documents', { latest: true, doctor_document_type_id: 11 }).then(function(documents) {
      return documents.pop();
    });
  };

  ctrl.save = function() {
    ctrl.errors = null;
    var promises = [];

    return $q.all(promises).catch(function(res) {
      return $q.reject(res.data.errors);
    });
  };

  $scope.$on('forms.doctors.educational-verification.willUploadDocument', function(e) {
    ctrl.uploadStatus = null;
  });

  $scope.$on('forms.doctors.educational-verification.didUploadDocument', function(e) {
    ctrl.errors = null;
    ctrl.uploadStatus = {
      msg: 'Upload completed successfully!',
      type: 'success'
    };
    getEducationalDocument().then(function(educationalDocument) {
      ctrl.document = educationalDocument;
    });
  });

  ctrl.saveAndSubmit = DoctorCredentialingSections.saveAndSubmit.bind(this);
  ctrl.saveForLater = DoctorCredentialingSections.saveForLater.bind(this);
}]);
