'use strict';

angular.module('doctors.ipa-selection.ctrl', ['vcp.api'])

.controller('DoctorIPASelectionCtrl', ['$window', '$q', 'Auth', 'CurrentDoctor', 'Restangular', function($window, $q, Auth, CurrentDoctor, Restangular) {
  var ctrl = this;

  $q.all([
    CurrentDoctor,
    Restangular.all('ipas').getList()
  ]).then(function(data) {
    ctrl.doctor = data[0];
    ctrl.ipas = data[1];
    ctrl.isLoaded = true;
  });

  ctrl.submit = function() {
    if (ctrl.isWaiting) {
      return;
    };

    ctrl.isWaiting = true;

    ctrl.doctor.patch().then(function() {
      $window.location = '/#/doctors/credentialing';
    }).finally(function() {
      ctrl.isWaiting = false;
    });
  };
}]);
