'use strict';

angular.module('fields.date-field.date-field.dir', [])

.directive('fieldsDateField', [function() {
  return {
    restrict: 'E',
    scope: {
      theDate: "=",
      label: "="
    },
    replace: true,
    templateUrl: '/fields/date-field/date-field.html',
    controller: [function() {}],
    controllerAs: 'ctrl',
    bindToController: true
  };
}]);
