'use strict';

angular.module('vcp.api', [
  'restangular'
])

.config(['RestangularProvider', function(RestangularProvider) {
  RestangularProvider.setBaseUrl('/api/v1');
  RestangularProvider.setDefaultHeaders({'Content-Type': 'application/json'});

  RestangularProvider.addResponseInterceptor(function(data, operation, what, url, response, deferred) {
    if (data && response.headers('X-Total-Count')) {
      data.totalCount = response.headers('X-Total-Count');
    };
    return data;
  });

  RestangularProvider.extendModel('affiliations', function(model) {
    ['created', 'updated', 'member_since'].forEach(function(field) {
      if (model[field]) {
        model[field] = new Date(model[field] * 1000);
      }
    });
    return model;
  });

  RestangularProvider.extendModel('doctors', function(model) {
    ['created', 'updated', 'birth_date', 'credentialing_first_approved', 'credentialing_last_approved'].forEach(function(field) {
      if (model[field]) {
        model[field] = new Date(model[field] * 1000);
      }
    });

    model.is_active = model.is_active == '1';

    model.makePayment = function(values) {
      return model.all('payments').customPOST(values);
    }

    return model;
  });

  RestangularProvider.extendModel('board-certification', function(model) {
    ['certification_date', 'expiration_date', 'recertification_date'].forEach(function(field) {
      if (model[field]) {
        model[field] = new Date(model[field] * 1000);
      }
    });
    return model;
  });

  RestangularProvider.extendModel('documents', function(model) {
    ['created', 'updated', 'verified'].forEach(function(field) {
      if (model[field]) {
        model[field] = new Date(model[field] * 1000);
      }
    });
    return model;
  });

  RestangularProvider.extendModel('insurance', function(model) {
    ['expiration_date'].forEach(function(field) {
      if (model[field]) {
        model[field] = new Date(model[field] * 1000);
      }
    });
    return model;
  });

  RestangularProvider.extendModel('ipas', function(model) {
    function prepareDate(data) {
      ['next_renewal'].forEach(function(field) {
        if (data[field]) {
          data[field] = new Date(data[field] * 1000);
        }
      });
      return data;
    }

    model.startRecredentialing = function() {
      return model.customPOST({
        cmd: 'start-doctor-credentialing'
      }).then(function(data) {
        _.extend(model, prepareDate(data));
      });
    };

    return prepareDate(model);
  });

  RestangularProvider.extendModel('dea-license', function(model) {
    ['expiration_date'].forEach(function(field) {
      if (model[field]) {
        model[field] = new Date(model[field] * 1000);
      }
    });
    return model;
  });

  RestangularProvider.extendModel('languages', function(model) {
    model.isSpokenByDoctor = model.isSpokenByDoctor == '1';
    model.isSpokenByStaff = model.isSpokenByStaff == '1';

    return model;
  });

  RestangularProvider.extendModel('state-licenses', function(model) {
    ['expiration_date'].forEach(function(field) {
      if (model[field]) {
        model[field] = new Date(model[field] * 1000);
      }
    });

    model.is_primary = model.is_primary == '1';

    return model;
  });

  RestangularProvider.extendModel('signatures', function(model) {
    ['created', 'updated'].forEach(function(field) {
      if (model[field]) {
        model[field] = new Date(model[field] * 1000);
      }
    });

    return model;
  });

  RestangularProvider.extendModel('transactions', function(model) {
    ['created', 'updated'].forEach(function(field) {
      if (model[field]) {
        model[field] = new Date(model[field] * 1000);
      }
    });
    return model;
  });

  RestangularProvider.extendModel('users', function(model) {
    ['created', 'updated', 'last_login'].forEach(function(field) {
      if (model[field]) {
        model[field] = new Date(model[field] * 1000);
      }
    });

    model.roles = model.roles || [];
    model.roles = model.roles.reduce(function(roles, role) {
      roles[role] = true;
      return roles;
    }, {});

    return model;
  });

  RestangularProvider.extendModel('work-history', function(model) {
    ['start_date', 'end_date'].forEach(function(field) {
      if (model[field]) {
        model[field] = new Date(model[field] * 1000);
      }
    });

    return model;
  });

  RestangularProvider.extendModel('status', function(model) {
    if (model.history) {
      model.history.forEach(function(history) {
        if (history.created) {
          history.created = new Date(history.created * 1000);
        }
      });
    }

    return model;
  });
}])

.factory('Auth', ['$http', '$q', '$rootScope', 'Restangular', function($http, $q, $rootScope, Restangular) {
  var roles = {
    none: null,
    login: 1,
    admin: 2,
    doctor: 3,
    auditor: 4,
    editor: 5,
    ipaAdmin: 6
  };

  var login = function(credentials) {
    return $http.post('/api/v1/auth', credentials).then(function(res) {
      var user = Restangular.restangularizeElement(null, res.data, 'users');

      $rootScope.$broadcast('auth.login', user);

      return user;
    }, function(res) {
      if (res.status == 422) {
        return $q.reject(new Error('Invalid email and/or password.'));
      } else if (res.status == 404) {
        return $q.reject(new Error('Unable to contact the authentication service.'));
      }

      return $q.reject(new Error(res.statusText));
    });
  };

  var logout = function() {
    return $http.delete('/api/v1/auth').then(function() {
      whoAmI().then(function(user) {
        $rootScope.$broadcast('auth.logout', user);
      })
    });
  };

  var whoAmI = function() {
    return $http.get('/api/v1/auth').then(function(res) {
      return Restangular.restangularizeElement(null, res.data, 'users');
    });
  };

  var requestPasswordReset = function(email) {
    return $http({
      method: 'POST',
      url: '/api/v1/auth/forgot-password',
      data: {
        email: email
      }
    }).catch(function(res) {
      if (res.status == 422) {
        return $q.reject(new Error('Could not find an account matching the provided email address.'));
      }

      return $q.reject(new Error(res.statusText));
    });
  };

  var verifyPasswordResetToken = function(token) {
    return $http({
      method: 'GET',
      url: '/api/v1/auth/reset-password/' + token
    }).then(function(res) {
      return res.data;
    }, function(res) {
      if (res.status == 404) {
        return $q.reject(new Error('Invalid reset token.'));
      }

      return $q.reject(new Error(res.statusText));
    });
  };

  var resetPassword = function(token, newPassword, confirmPassword) {
    return $http({
      method: 'POST',
      url: '/api/v1/auth/reset-password/' + token,
      data: {
        password: newPassword,
        password_confirm: confirmPassword
      }
    }).then(function(res) {
      return res.data;
    }, function(res) {
      if (res.status == 422) {
        var error = new Error('Invalid password.');
        error.errors = res.data.errors;
        return $q.reject(error);
      } else if (res.status == 404) {
        return $q.reject(new Error('Invalid reset token.'));
      }

      return $q.reject(new Error(res.statusText));
    });
  };

  var generatePassword = function(characterSet, size) {
    var result = "";

    for (var i = 0; i < size; i++) {
      result += characterSet.charAt(Math.floor(Math.random() * characterSet.length));
    }

    return result;
  }

  var getRandomPassword = function() {
    return generatePassword("ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789", 8);
  }

  return {
    login: login,
    logout: logout,
    whoAmI: whoAmI,
    requestPasswordReset: requestPasswordReset,
    verifyPasswordResetToken: verifyPasswordResetToken,
    resetPassword: resetPassword,
    roles: roles,
    generatePassword: generatePassword,
    getRandomPassword: getRandomPassword
  };
}])

.factory('CurrentUser', ['Auth', 'Restangular', function(Auth, Restangular) {
  return Auth.whoAmI();
}])

.factory('CurrentDoctor', ['Auth', 'Restangular', function(Auth, Restangular) {
  return Auth.whoAmI().then(function(user) {
    return Restangular.one('doctors', user.doctor_id).get();
  });
}]);
