'use strict';

angular.module('forms.doctors.provider-panels.dir', ['checklist-model'])

.directive('formDoctorProviderPanels', [function() {
  return {
    restrict: 'E',
    scope: {
      panels: '=',
      selected: '=',
      errors: '='
    },
    replace: true,
    templateUrl: '/forms/doctors/provider-panels/provider-panels.html',
    controller: [function() {}],
    controllerAs: 'ctrl',
    bindToController: true
  };
}]);
