'use strict';

angular.module('doctors.affirmation-of-supplied-information.ctrl', ['forms.doctors.affirmation-of-supplied-information.dir'])

.controller('DoctorAffirmationOfSuppliedInformationCtrl', ['$q', 'DoctorCredentialingSections', 'CurrentDoctor', 'Restangular', function($q, DoctorCredentialingSections, CurrentDoctor, Restangular) {
  var ctrl = this;

  Restangular.one('doctor-credentialing-sections', 18).get().then(function(section) {
    ctrl.section = section;
  });

  CurrentDoctor.then(function(doctor) {
    ctrl.doctor = doctor;
    return $q.all([
      doctor.one('status', 18).get(),
      Restangular.one('ipas', doctor.ipa_id).get(),
      doctor.getList('signatures', { type: 'affirmation' })
    ]);
  }).then(function(data) {
    ctrl.status = data[0];
    ctrl.ipa = data[1];
    ctrl.signature = data[2].shift() || {};
    ctrl.isLoaded = true;
  });

  ctrl.save = function() {
    ctrl.errors = null;
    return $q.all([
      ctrl.doctor.all('signatures').post({
        type: 'affirmation',
        accepted: ctrl.signature.accepted,
        authorization: ctrl.signature.authorization
      })
    ]).catch(function(res) {
      return $q.reject(res.data.errors);
    });
  };

  ctrl.saveAndSubmit = DoctorCredentialingSections.saveAndSubmit.bind(this);
  ctrl.saveForLater = DoctorCredentialingSections.saveForLater.bind(this);
}]);
