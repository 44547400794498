'use strict';

angular.module('forms.doctors.educational-verification.dir', ['angularFileUpload'])

.directive('formDoctorEducationalVerification', [function() {
  return {
    restrict: 'E',
    scope: {
      doctor: '=',
      errors: '='
    },
    replace: true,
    templateUrl: '/forms/doctors/educational-verification/educational-verification.html',
    controller: ['$rootScope', '$scope', 'FileUploader', function($rootScope, $scope, FileUploader) {
      var ctrl = this;

      ctrl.uploader = new FileUploader({
        alias: 'document',
        autoUpload: true,
        removeAfterUpload: true,
        formData: [{ 'doctor_document_type_id': 11 }]
      });

      ctrl.uploader.onBeforeUploadItem = function() {
        $scope.$emit('forms.doctors.educational-verification.willUploadDocument');
      };

      ctrl.uploader.onSuccessItem = function() {
        ctrl.errors = null;
        ctrl.uploadStatus = {
          msg: 'Upload completed successfully!',
          type: 'success'
        };
        $scope.$emit('forms.doctors.educational-verification.didUploadDocument');
      };

      $scope.$watch(function() {
        return ctrl.doctor;
      }, function() {
        if (ctrl.doctor) {
          ctrl.uploader.url = '/api/v1/doctors/' + ctrl.doctor.id + '/documents';
        } else {
          ctrl.uploader.url = '/';
        }
      })
    }],
    controllerAs: 'ctrl',
    bindToController: true
  };
}]);
