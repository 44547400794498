'use strict';

angular.module('section-status.dir', [])

.directive('sectionStatus', [function() {
  return {
    restrict: 'E',
    scope: {
      status: '='
    },
    replace: true,
    templateUrl: '/doctors/credentialing-sections/section-status.html'
  };
}]);
