'use strict';

angular.module('doctors.documentation.ctrl', ['forms.doctors.documentation.dir'])

.controller('DoctorDocumentationCtrl', ['$q', 'FileUploader', 'DoctorCredentialingSections', 'CurrentDoctor', 'Restangular', function($q, FileUploader, DoctorCredentialingSections, CurrentDoctor, Restangular) {
  var ctrl = this;

  Restangular.one('doctor-credentialing-sections', 7).get().then(function(section) {
    ctrl.section = section;
  });

  CurrentDoctor.then(function(doctor) {
    ctrl.doctor = doctor;
    return $q.all([
      doctor.one('status', 7).get(),
      doctor.getList('documents', { latest: true }),
      Restangular.all('doctor-document-types').getList({ uploaded_by_roles: 3, uploaded_by_ipas: doctor.ipa_id }),
      Restangular.one('settings').get(),
      Restangular.one('ipas', ctrl.doctor.ipa_id).get()
    ]);
  }).then(function(data) {
    ctrl.status = data[0];

    var documentsGroupedByType = data[1].reduce(function(documents, document) {
      documents[document.doctor_document_type_id] = document;
      return documents;
    }, {});

    ctrl.documentTypes = data[2].map(function(documentType) {
      documentType.lastUpload = documentsGroupedByType[documentType.id];
      return documentType;
    });

    ctrl.settings = data[3];
    ctrl.ipa = data[4];
    ctrl.isLoaded = true;
  });

  ctrl.save = function() {
    ctrl.errors = null;
    var promises = [];

    return $q.all(promises).catch(function(res) {
      return $q.reject(res.data.errors);
    });
  };

  ctrl.saveAndSubmit = DoctorCredentialingSections.saveAndSubmit.bind(this);
  ctrl.saveForLater = DoctorCredentialingSections.saveForLater.bind(this);
}]);
