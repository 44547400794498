'use strict';

angular.module('forms.authorize-net-messages.dir', [])

.directive('formAuthorizeNetMessages', ['$location', function($location) {
  return {
    restrict: 'E',
    scope: {
      errors: '='
    },
    templateUrl: '/forms/authorize-net-messages/authorize-net-messages.html',
    controller: ['$scope', '$log', function($scope, $log) {
      var ctrl = this;
      $log.debug("running form-authorize-net-messages");
      ctrl.isSecure = $location.protocol() === 'https';
    }],
    controllerAs: 'ctrl',
    bindToController: true
  };
}]);
