'use strict';

angular.module('forms.doctors.single-signature-provider-acknowledgment.dir', [])

.directive('formDoctorSingleSignatureProviderAcknowledgment', [function() {
  return {
    restrict: 'E',
    scope: {
      doctor: '=',
      ipa: '=',
      signature: '=',
      errors: '='
    },
    replace: true,
    templateUrl: '/forms/doctors/single-signature-provider-acknowledgment/single-signature-provider-acknowledgment.html',
    controller: [function() {}],
    controllerAs: 'ctrl',
    bindToController: true
  };
}]);
