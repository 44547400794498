'use strict';

angular.module('forms.doctors.authorization-and-release.dir', [])

.directive('formDoctorAuthorizationAndRelease', [function() {
  return {
    restrict: 'E',
    scope: {
      ipa: '=',
      signature: '=',
      errors: '='
    },
    replace: true,
    templateUrl: '/forms/doctors/authorization-and-release/authorization-and-release.html',
    controller: [function() {}],
    controllerAs: 'ctrl',
    bindToController: true
  };
}]);
