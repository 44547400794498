'use strict';

angular.module('forms.doctors.participation-fees.dir', [])

.directive('formDoctorParticipationFees', ['$location', function($location) {
  return {
    restrict: 'E',
    scope: {
      ipa: '=',
      balance: '=',
      values: '=',
      errors: '='
    },
    replace: true,
    templateUrl: '/forms/doctors/participation-fees/participation-fees.html',
    controller: ['$scope', 'Restangular', function($scope, Restangular) {
      var ctrl = this;

      ctrl.isSecure = $location.protocol() === 'https';

      ctrl.months = [
        { name: '01 Jan', value: '01' },
        { name: '02 Feb', value: '02' },
        { name: '03 Mar', value: '03' },
        { name: '04 Apr', value: '04' },
        { name: '05 May', value: '05' },
        { name: '06 Jun', value: '06' },
        { name: '07 Jul', value: '07' },
        { name: '08 Aug', value: '08' },
        { name: '09 Sep', value: '09' },
        { name: '10 Oct', value: '10' },
        { name: '11 Nov', value: '11' },
        { name: '12 Dec', value: '12' }
      ];

      var years = [];
      for (var year = new Date().getFullYear(); years.length <= 10; year++) {
        years.push(year);
      }
      ctrl.years = years;

      var singleFeeDescriptions = {
        day: 'daily',
        month: 'monthly',
        year: 'yearly'
      };

      var pluralFeeDescriptions = {
        day: 'days',
        month: 'months',
        year: 'years'
      };

      $scope.$watch(function() { return ctrl.ipa; }, function() {
        if (!ctrl.ipa) {
          ctrl.feeFrequencyDescription = '';
          return;
        }

        if (ctrl.ipa.fee_frequency_amount == 1) {
          ctrl.feeFrequencyDescription = singleFeeDescriptions[ctrl.ipa.fee_frequency_unit] + ' fee';
        } else {
          ctrl.feeFrequencyDescription = 'fee every ' + ctrl.ipa.fee_frequency_amount + ' ' + pluralFeeDescriptions[ctrl.ipa.fee_frequency_unit];
        }
      });

      Restangular.one('settings').get().then(function(settings) {
        ctrl.settings = settings;
      });
    }],
    controllerAs: 'ctrl',
    bindToController: true
  };
}]);
