'use strict';

angular.module('forms.sign-up.dir', ['sign-up.ctrl'])

.directive('formSignUp', [function() {
  return {
    restrict: 'E',
    scope: false,
    replace: true,
    templateUrl: '/forms/sign-up/sign-up.html',
    controller: 'SignUpCtrl',
    controllerAs: 'signUpCtrl',
    bindToController: true
  };
}]);
