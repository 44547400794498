'use strict';

angular.module('doctors.panel-applications.ctrl', ['forms.doctors.panel-applications.dir'])

.controller('DoctorPanelApplicationsCtrl', ['$q', 'DoctorCredentialingSections', 'CurrentDoctor', 'Restangular', function($q, DoctorCredentialingSections, CurrentDoctor, Restangular) {
  var ctrl = this;

  ctrl.panels = [];
  ctrl.selectedPanels = [];

  Restangular.one('doctor-credentialing-sections', 13).get().then(function(section) {
    ctrl.section = section;
  });

  CurrentDoctor.then(function(doctor) {
    ctrl.doctor = doctor;
    return $q.all([
      Restangular.one('ipas', doctor.ipa_id).getList('panels'),
      doctor.getList('panels'),
      doctor.one('status', 13).get()
    ]);
  }).then(function(data) {
    ctrl.panels = data[0];
    ctrl.selectedPanels = data[1].map(function(panel) {
      return panel.id;
    });
    ctrl.status = data[2];
    ctrl.isLoaded = true;
  });

  ctrl.save = function() {
    ctrl.errors = null;
    return $q.all([
      ctrl.doctor.all('panels').customPUT(ctrl.selectedPanels)
    ]).catch(function(res) {
      return $q.reject(res.data.errors);
    });
  };

  ctrl.saveAndSubmit = DoctorCredentialingSections.saveAndSubmit.bind(this);
  ctrl.saveForLater = DoctorCredentialingSections.saveForLater.bind(this);
}]);
