'use strict';

angular.module('doctors.vision-care-direct.ctrl', ['forms.doctors.vision-care-direct.dir'])

.controller('DoctorVisionCareDirectCtrl', ['$q', 'DoctorCredentialingSections', 'CurrentDoctor', 'Restangular', function($q, DoctorCredentialingSections, CurrentDoctor, Restangular) {
  var ctrl = this;

  ctrl.questions = [];

  Restangular.one('doctor-credentialing-sections', 14).get().then(function(section) {
    ctrl.section = section;
  });

  CurrentDoctor.then(function(doctor) {
    ctrl.doctor = doctor;
    return $q.all([
      doctor.one('status', 14).get(),
      doctor.getList('questions', { type: 'vcd', embed: 'answer' })
    ]);
  }).then(function(data) {
    ctrl.status = data[0];
    ctrl.questions = data[1];
    ctrl.isLoaded = true;
  });

  ctrl.save = function () {
    ctrl.errors = null;

    var promises = [];

    ctrl.questions.forEach(function(question) {
      if (!question.answer.response) {
        return;
      }
      promises.push(question.one('answer').patch(question.answer));
    });

    return $q.all(promises).catch(function(res) {
      return $q.reject(res.data.errors);
    });
  };

  ctrl.saveAndSubmit = DoctorCredentialingSections.saveAndSubmit.bind(this);
  ctrl.saveForLater = DoctorCredentialingSections.saveForLater.bind(this);
}]);
