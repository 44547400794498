'use strict';

angular.module('doctors.ctrl', ['vcp.api'])

.controller('DoctorsCtrl', ['$window', 'Auth', function($location, Auth) {
  var ctrl = this;

  Auth.whoAmI().then(function(currentUser) {
    ctrl.currentUser = currentUser;
    if (currentUser.roles.doctor) {
      $location.path('/#/doctors/welcome');
    }
    ctrl.isLoaded = true;
  });
}])

.config(['$locationProvider', '$routeProvider', function($locationProvider, $routeProvider) {
  $routeProvider
    .when('/doctors', {
      templateUrl: '/doctors/doctors.html',
      cms: true
    })
    .when('/doctors/welcome', {
      templateUrl: '/doctors/welcome/welcome.html',
      cms: true
    })
    .when('/doctors/:page', {
      templateUrl: function(params) {
        return '/doctors/' + params.page + '/' + params.page + '.html';
      }
    });
}]);
