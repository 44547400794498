'use strict';

angular.module('doctors.participation-fees.ctrl', ['forms.authorize-net-payment-button.dir', 'forms.authorize-net-messages.dir'])

.controller('DoctorParticipationFeesCtrl', ['$q', 'CurrentDoctor', 'DoctorCredentialingSections', 'Restangular',
  'authorizeNet', '$scope', '$log', function($q, CurrentDoctor, DoctorCredentialingSections, Restangular, authorizeNet, $scope, $log) {
  var ctrl = this;

  ctrl.errors = [];

  ctrl.lastPayment = false;

  Restangular.one('doctor-credentialing-sections', 19).get().then(function(section) {
    ctrl.section = section;
  });

  CurrentDoctor.then(function(doctor) {
    ctrl.isLoaded = true;
    ctrl.doctor = doctor;
    $q.all([
      ctrl.doctor.one('status', 19).get(),
      ctrl.doctor.one('payments').get(),
      ctrl.doctor.getList('transactions', { complete: false }),
      Restangular.one('ipas', doctor.ipa_id).get()
    ]).then(function(data) {
      ctrl.status = data[0];
      ctrl.balance = data[1].balance;
      ctrl.transactions = data[2];
      ctrl.ipa = data[3];
      ctrl.isLoaded = true;
    });
  });

  ctrl.submit = function(authorizeNetData) {
    if (ctrl.balance < 0) {
      ctrl.makePayment(authorizeNetData);
    } else {
      ctrl.saveAndSubmit();
    }
  };
  authorizeNet.registerResponseHandler(function(authorizeNetData){
    ctrl.submit(authorizeNetData);
  });

  authorizeNet.registerErrorHandler(function (authorizeNetError){
    $scope.$apply(function(){
      $log.error(authorizeNetError);
      // convert authorizeNetError format into array of error messages
      ctrl.errors = authorizeNetError.message.map(function(obj){ return obj["text"]; });;
    });
  });

  ctrl.makePayment = function(authorizeNetData) {
    if (ctrl.isWaiting) return;
    ctrl.errors = null;
    ctrl.isWaiting = true;
    ctrl.doctor.makePayment(authorizeNetData)
      .then(function(transaction) {
        transaction = Restangular.restangularizeElement(null, transaction, 'transactions')
        ctrl.transactions.push(transaction);
        return ctrl.doctor.one('payments').get();
      })
      .then(function(paymentData) {
          ctrl.lastPayment = -ctrl.balance;
          ctrl.balance = paymentData.balance;
        }, function(res) {
          // handle both the "res.data" format from server, and "res.errors" from 3rd party service
          var copy = (res.data && res.data.errors && res.data.errors.message) || (res.errors && res.errors.message);
          ctrl.errors = [copy];
      })
      .finally(function() {
        ctrl.isWaiting = false;
      });
  };

  ctrl.save = function() {
    ctrl.errors = null;
    return $q.all([]).catch(function(res) {
      return $q.reject(res.data.errors);
    });
  };

  ctrl.saveAndSubmit = DoctorCredentialingSections.saveAndSubmit.bind(this);
  ctrl.saveForLater = DoctorCredentialingSections.saveForLater.bind(this);
}]);
