'use strict';

angular.module('forms.electronic-signature', [])

.directive('electronicSignature', [function() {
  return {
    restrict: 'E',
    scope: {
      download: '=',
      errors: '=',
      signature: '=ngModel',
      text: '@'
    },
    replace: true,
    templateUrl: '/forms/electronic-signature/electronic-signature.html',
    controller: ['$scope', function ($scope) {
    }]
  };
}]);
