'use strict';

angular.module('doctors.requirements-for-participation.ctrl', ['forms.doctors.requirements-for-participation.dir'])

.controller('DoctorRequirementsForParticipationCtrl', ['$q', '$sce', 'DoctorCredentialingSections', 'CurrentDoctor', 'Restangular', function($q, $sce, DoctorCredentialingSections, CurrentDoctor, Restangular) {
  var ctrl = this;

  ctrl.requirements = '';

  Restangular.one('doctor-credentialing-sections', 16).get().then(function(section) {
    ctrl.section = section;
  });

  CurrentDoctor.then(function(doctor) {
    ctrl.doctor = doctor;
    return $q.all([
      doctor.one('status', 16).get(),
      doctor.getList('signatures', { type: 'requirements' }),
      Restangular.one('ipas', doctor.ipa_id).one('requirements-for-participation').get(),
      Restangular.one('ipas', doctor.ipa_id).get()
    ]);
  }).then(function(data) {
    ctrl.status = data[0];
    ctrl.signature = data[1].shift() || {};
    ctrl.requirements = $sce.trustAsHtml(data[2]);
    ctrl.ipa = data[3];
    ctrl.isLoaded = true;
  });

  ctrl.save = function() {
    ctrl.errors = null;
    return $q.all([
      ctrl.doctor.all('signatures').post({
        type: 'requirements',
        accepted: ctrl.signature.accepted,
        authorization: ctrl.signature.authorization
      })
    ]).catch(function(res) {
      return $q.reject(res.data.errors);
    });
  };

  ctrl.saveAndSubmit = DoctorCredentialingSections.saveAndSubmit.bind(this);
  ctrl.saveForLater = DoctorCredentialingSections.saveForLater.bind(this);
}]);
