'use strict';

angular.module('forms.validation.errors.dir', [])

.directive('formsValidationErrors', [function() {
  return {
    restrict: 'E',
    scope: {
      errors: '='
    },
    replace: true,
    templateUrl: '/forms/validation/errors.html',
    controller: [function() {
      var ctrl = this;
    }],
    controllerAs: 'ctrl',
    bindToController: true
  };
}]);
