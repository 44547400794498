'use strict';

angular.module('sign-up.ctrl', ['vcp.api'])

.controller('SignUpCtrl', ['$window', 'Auth', 'Restangular', function($window, Auth, Restangular) {
  var ctrl = this;

  ctrl.isWaiting = false;

  ctrl.submit = function() {
    ctrl.isWaiting = true;
    Restangular.all('doctors').post(ctrl.values).then(function(res) {
      return Auth.login(ctrl.values).then(function(res) {
        $window.location = '/#/doctors/welcome';
      });
    }, function(res) {
      ctrl.errors = res.data.errors;
    }).finally(function() {
      ctrl.isWaiting = false;
    });
  };
}]);
