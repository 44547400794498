'use strict';

angular.module('doctors.professional-affiliations.ctrl', ['forms.doctors.professional-affiliations.dir'])

.controller('DoctorProfessionalAffiliationsCtrl', ['$q', '$scope', 'DoctorCredentialingSections', 'CurrentDoctor', 'Restangular', function($q, $scope, DoctorCredentialingSections, CurrentDoctor, Restangular) {
  var ctrl = this;

  ctrl.affiliationTypes = [];
  ctrl.affiliations = [];
  ctrl.affiliationsQueuedForRemoval = [];

  Restangular.one('doctor-credentialing-sections', 12).get().then(function(section) {
    ctrl.section = section;
  });

  CurrentDoctor.then(function(doctor) {
    ctrl.doctor = doctor;
    return $q.all([
      doctor.one('status', 12).get(),
      Restangular.one('ipas', doctor.ipa_id).all('affiliation-types').getList(),
      doctor.all('affiliations').getList()
    ]);
  }).then(function(data) {
    ctrl.status = data[0];
    ctrl.affiliationTypes = data[1];
    ctrl.affiliations = data[2];
    ctrl.isLoaded = true;
  });

  ctrl.save = function() {
    ctrl.errors = null;
    var promises = [];

    ctrl.affiliations.forEach(function(affiliation) {
      promises.push(affiliation.id ? affiliation.patch() : ctrl.affiliations.post(affiliation));
    });

    ctrl.affiliationsQueuedForRemoval.forEach(function(affiliation) {
      promises.push(affiliation.remove());
    });

    return $q.all(promises).catch(function(res) {
      return $q.reject(res.data.errors);
    });
  };

  $scope.$on('forms.doctors.professional-affiliations.didRemoveAffiliation', function(e, affiliation) {
    if (!affiliation.id) {
      return;
    };
    ctrl.affiliationsQueuedForRemoval.push(affiliation);
  });

  ctrl.saveAndSubmit = DoctorCredentialingSections.saveAndSubmit.bind(this);
  ctrl.saveForLater = DoctorCredentialingSections.saveForLater.bind(this);
}]);
