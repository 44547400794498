'use strict';

angular.module('welcome.ctrl', [])

.controller('WelcomeCtrl', ['$scope', function() {
  var ctrl = this;

  ctrl.slides = [{
    image: '/img/slides/scope.jpg',
    header: 'Keeping to a standard',
    text: 'We are a precision fit.',
    link: '#/about'
  }, {
    image: '/img/slides/glasses.jpg',
    header: 'The Right Fit',
    text: 'Helping to build trust with your patients.',
    link: '#/about'
  }];
}])

.config(['$locationProvider', '$routeProvider', function($locationProvider, $routeProvider) {
  $routeProvider
    .when('/', {
      templateUrl: '/welcome/welcome.html',
      cms: true
    });
}]);
