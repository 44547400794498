'use strict';

angular.module('forms.doctors.additional-practices.dir', ['forms.doctors.additional-practice.dir'])

.directive('formDoctorAdditionalPractices', [function() {
  return {
    restrict: 'E',
    scope: {
      readonly: '=',
      practices: '=',
      errors: '=',
      disableDeletion: '='
    },
    replace: true,
    templateUrl: '/forms/doctors/additional-practices/additional-practices.html',
    controller: ['$scope', function($scope) {
      var ctrl = this;

      ctrl.addPractice = function() {
        ctrl.practices.push({
          is_accepting_new_patients: "1",
          offers_telehealth: "0",
          has_different_claims_address: "0",
          mailing_different_from_claims_address: "0"
        });
      };

      ctrl.removePractice = function(practice) {
        ctrl.practices.splice(ctrl.practices.indexOf(practice), 1);
        $scope.$emit('forms.doctors.additional-practices.didRemovePractice', practice);
      };

      if(ctrl.readonly){
        ctrl._addPracticeCached = ctrl.addPractice;
        ctrl.addPractice = function() { null; }

        ctrl._removePracticeCached = ctrl.removePractice;
        ctrl.removePractice = function() { null; }
      }
    }],
    controllerAs: 'ctrl',
    bindToController: true
  };
}]);
