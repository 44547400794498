'use strict';

angular.module('forms.authorize-net-payment-button.dir', [])
    .factory("authorizeNet", ["$window", "$log", function($window, $log){
      var service = this;

      service.handleResponse = function(){ $log.warn("handleResponse has not been set!"); };
      service.handleErrors = function(){ $log.warn("handleErrors has not been set!"); };

      var aNetResponseHandler = "authorizeNetResponseHandler";
      var aNetAcceptUIClass = "AcceptUI"; // this value *must* be AcceptUI for the AuthorizeNet AcceptUI code to function properly.

      // Test environment specific. TODO update to something that will allow these to be injected at compile time.

      var aNetApiLoginId = "843gQC6E6y2v";
      var aNetPublicClientKey = "4TdH7V2QVJK6n8BddV9k4Mr9b5ShGULu76GKTmTQ2Fnv9UHzw2gY6dgws5F2Mk93";
      var aNetAcceptUiUrl = "https://js.authorize.net/v3/AcceptUI.js";

      $window[aNetResponseHandler] = function(response) {
        if (response.messages.resultCode === "Error") {
           $log.info("aNetErrorHandler called. Triggering the handleErrors callback!");
           service.handleErrors(response.messages);
        } else {
          $log.info("aNetResponseHandler called. Triggering the handleResponse callback");
          service.handleResponse(response.opaqueData);
        }
      };

      return {
        "aNetResponseHandler": aNetResponseHandler,
        "aNetAcceptUIClass": aNetAcceptUIClass,
        "aNetApiLoginId": aNetApiLoginId,
        "aNetPublicClientKey": aNetPublicClientKey,
        "aNetAcceptUiUrl": aNetAcceptUiUrl,
        "registerResponseHandler": function(callback){ service.handleResponse = callback; },
        "registerErrorHandler": function(callback){ service.handleErrors = callback; },
      };
    }])
.directive('formAuthorizeNetPaymentButton', ['$location', '$log', 'authorizeNet', function($location, $log, authorizeNet) {
  return {
    restrict: 'E',
    scope: {},
    link: {
      post: function (scope, elem, attr){
        // load and run the authorize.net code after the element is created
        var tag = document.createElement("script");
        tag.src = authorizeNet["aNetAcceptUiUrl"];
        tag.type = 'text/javascript';
        tag.async = true;
        tag.charset = 'utf-8';
        elem.append(tag);
      }
    },
    replace: true,
    templateUrl: '/forms/authorize-net-payment-button/authorize-net-payment-button.html',
    controller: ['$scope', 'Restangular', 'authorizeNet', function($scope, Restangular, authorizeNet) {
      var ctrl = this;

        $scope.aNetAcceptUIClass = authorizeNet["aNetAcceptUIClass"];
      $scope.aNetResponseHandler = authorizeNet["aNetResponseHandler"]; // why not a direct reference?
      $scope.aNetPublicClientKey = authorizeNet["aNetPublicClientKey"];
      $scope.aNetApiLoginId = authorizeNet["aNetApiLoginId"];

    }],
    controllerAs: 'ctrl',
    bindToController: true
  };
}]);
