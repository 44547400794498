'use strict';

angular.module('doctors.work-history.ctrl', ['forms.doctors.work-history.dir'])

.controller('DoctorWorkHistoryCtrl', ['$q', '$scope', 'DoctorCredentialingSections', 'CurrentDoctor', 'Restangular', function($q, $scope, DoctorCredentialingSections, CurrentDoctor, Restangular) {
  var ctrl = this;

  ctrl.workHistory = [];
  ctrl.workHistoryQueuedForRemoval = [];

  Restangular.one('doctor-credentialing-sections', 4).get().then(function(section) {
    ctrl.section = section;
  });

  CurrentDoctor.then(function(doctor) {
    ctrl.doctor = doctor;
    return $q.all([
      doctor.one('status', 4).get(),
      doctor.getList('work-history')
    ]);
  }).then(function(data) {
    ctrl.status = data[0];
    ctrl.workHistory = data[1];
    ctrl.isLoaded = true;
  });

  ctrl.save = function() {
    ctrl.errors = null;
    var promises = [];

    ctrl.workHistory.forEach(function(history) {
      promises.push(history.id ? history.patch() : ctrl.workHistory.post(history));
    });

    ctrl.workHistoryQueuedForRemoval.forEach(function(history) {
      promises.push(history.remove());
    });

    return $q.all(promises).then(function(res) {
      ctrl.isEditing = false;
    }, function(res) {
      ctrl.errors = res.data.errors;
    }).finally(function() {
      ctrl.isWaiting = false;
    });
  };

  $scope.$on('forms.doctors.work-history.didRemoveWorkHistory', function(e, workHistory) {
    if (!workHistory.id) return;
    ctrl.workHistoryQueuedForRemoval.push(workHistory);
  });

  ctrl.saveAndSubmit = DoctorCredentialingSections.saveAndSubmit.bind(this);
  ctrl.saveForLater = DoctorCredentialingSections.saveForLater.bind(this);
}]);
