'use strict';

angular.module('doctors.credentialing.ctrl', ['doctor-credentialing-sections.srv'])

.controller('DoctorCredentialingCtrl', ['$q', 'CurrentDoctor', 'DoctorCredentialingSections', 'Restangular', function($q, CurrentDoctor, DoctorCredentialingSections, Restangular) {
  var ctrl = this;

  CurrentDoctor.then(function(doctor) {
    ctrl.doctor = doctor;
    return $q.all([
      DoctorCredentialingSections.getStatusForDoctor(doctor),
      Restangular.one('ipas', ctrl.doctor.ipa_id).get()
    ]);
  }).then(function(data) {
    ctrl.status = data[0];
    ctrl.ipa = data[1];
    ctrl.nextSection = DoctorCredentialingSections.findNextSection(ctrl.status, 4);
    ctrl.isLoaded = true;
  });

  ctrl.submitForReview = function() {
    if (ctrl.isWaiting) {
      return;
    }

    ctrl.isWaiting = true;

    DoctorCredentialingSections.submitDoctorForReview(ctrl.doctor).then(function() {
      return DoctorCredentialingSections.getStatusForDoctor(ctrl.doctor);
    }).then(function(status) {
      ctrl.status = status;
    }).finally(function() {
      ctrl.isWaiting = false;
    });
  };
}]);
