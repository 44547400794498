'use strict';

angular.module('forgot-password.ctrl', ['vcp.api'])

.controller('ForgotPasswordCtrl', ['Auth', function(Auth) {
  var ctrl = this;

  ctrl.alerts = [];

  ctrl.closeAlert = function(index) {
    ctrl.alerts.splice(index, 1);
  };

  ctrl.submit = function(form) {
    ctrl.isWaiting = true;
    ctrl.alerts = [];
    Auth.requestPasswordReset(ctrl.values.email).then(function() {
  	  ctrl.alerts.push({
  	    type: 'success',
  		  msg: 'Password reset instructions sent to: ' + ctrl.values.email
  	  });
  	  ctrl.values = {};
      if (form) {
        form.$setUntouched();
      }
    }, function(err) {
      if (err) {
        ctrl.alerts.push({
          type: 'danger',
          msg: err.message
        });
      }
    }).finally(function() {
      ctrl.isWaiting = false;
    });
  };
}])

.config(['$locationProvider', '$routeProvider', function($locationProvider, $routeProvider) {
  $routeProvider
    .when('/forgot-password', {
      templateUrl: '/forgot-password/forgot-password.html'
    });
}]);
