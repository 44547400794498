'use strict';

angular.module('forms.doctors.languages.dir', [])

.directive('formDoctorLanguages', [function() {
  return {
    restrict: 'E',
    scope: {
      languages: '=',
      errors: '='
    },
    replace: true,
    templateUrl: '/forms/doctors/languages/languages.html',
    controller: ['$scope', function($scope) {
      var ctrl = this;

      ctrl.addLanguage = function() {
        ctrl.languages.push({});
      };

      ctrl.removeLanguage = function(language) {
        if (language.defaultLanguage) return;

        ctrl.languages.splice(ctrl.languages.indexOf(language), 1);

        $scope.$emit('forms.doctors.languages.didRemoveLanguage', language);
      };
    }],
    controllerAs: 'ctrl',
    bindToController: true
  };
}]);
