'use strict';

angular.module('forms.doctors.references.dir', ['angularFileUpload'])

.directive('formDoctorReferences', [function() {
  return {
    restrict: 'E',
    scope: {
      doctor: '=',
      references: '=',
      errors: '='
    },
    replace: true,
    templateUrl: '/forms/doctors/references/references.html',
    controller: ['$scope', 'FileUploader', function($scope, FileUploader) {
      var ctrl = this;

      ctrl.uploadStatus = null;

      ctrl.uploader = new FileUploader({
        alias: 'document',
        autoUpload: true,
        removeAfterUpload: true,
        formData: [{ 'doctor_document_type_id': 10 }]
      });

      ctrl.uploader.onBeforeUploadItem = function() {
        ctrl.uploadStatus = null;
      };

      ctrl.uploader.onSuccessItem = function() {
        ctrl.uploadStatus = {
          msg: 'Upload completed successfully!',
          type: 'success'
        };
        refreshReferencesDocument();
      };

      $scope.$watch(function() { return ctrl.doctor }, function() {
        if (!ctrl.doctor) return;
        ctrl.uploader.url = '/api/v1/doctors/' + ctrl.doctor.id + '/documents';
        refreshReferencesDocument();
      });

      function refreshReferencesDocument() {
        if (!ctrl.doctor) return;
        return ctrl.doctor.all('documents').getList({ latest: true, doctor_document_type_id: 10 }).then(function(documents) {
          ctrl.document = documents.pop();
        });
      }
    }],
    controllerAs: 'ctrl',
    bindToController: true
  };
}]);
