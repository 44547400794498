'use strict';

angular.module('doctors.references.ctrl', ['vcp.api', 'forms.doctors.references.dir'])

.controller('DoctorReferencesCtrl', ['$q', 'DoctorCredentialingSections', 'CurrentDoctor', 'Restangular', function($q, DoctorCredentialingSections, CurrentDoctor, Restangular) {
  var ctrl = this;

  Restangular.one('doctor-credentialing-sections', 6).get().then(function(section) {
    ctrl.section = section;
  });

  CurrentDoctor.then(function(doctor) {
    ctrl.doctor = doctor;
    return $q.all([
      doctor.one('status', 6).get(),
      doctor.getList('references')
    ]);
  }).then(function(data) {
    ctrl.status = data[0];
    ctrl.references = data[1];

    while (ctrl.references.length < 2) {
      ctrl.references.push({});
    }

    ctrl.isLoaded = true;
  });

  ctrl.save = function() {
    ctrl.errors = null;
    var promises = [];

    ctrl.references.forEach(function(reference) {
      promises.push(reference.id ? reference.patch() : ctrl.references.post(reference));
    });

    return $q.all(promises).catch(function(res) {
      return $q.reject(res.data.errors);
    });
  };

  ctrl.saveAndSubmit = DoctorCredentialingSections.saveAndSubmit.bind(this);
  ctrl.saveForLater = DoctorCredentialingSections.saveForLater.bind(this);
}]);
