'use strict';

angular.module('doctors.employed-by.ctrl', ['forms.doctors.employed-by.dir'])

.controller('DoctorEmployedByCtrl', ['$q', '$scope', 'DoctorCredentialingSections', 'CurrentDoctor', 'Restangular', function($q, $scope, DoctorCredentialingSections, CurrentDoctor, Restangular) {
  var ctrl = this;

  ctrl.colleagues = [];
  ctrl.colleaguesQueuedForRemoval = [];

  Restangular.one('doctor-credentialing-sections', 10).get().then(function(section) {
    ctrl.section = section;
  });

  CurrentDoctor.then(function(doctor) {
    ctrl.doctor = doctor;
    return $q.all([
      doctor.one('status', 10).get(),
      doctor.getList('colleagues', { type: 'employer' })
    ]);
  }).then(function(data) {
    ctrl.status = data[0];
    ctrl.colleagues = data[1];
    ctrl.isLoaded = true;
  });

  ctrl.save = function() {
    ctrl.errors = null;
    var promises = [];

    ctrl.colleagues.forEach(function(colleague) {
      promises.push(colleague.id ? colleague.patch() : ctrl.colleagues.post(colleague));
    });

    ctrl.colleaguesQueuedForRemoval.forEach(function(colleague) {
      if (!colleague.id) return;
      promises.push(colleague.remove());
    });

    return $q.all(promises).catch(function(res) {
      return $q.reject(res.data.errors);
    });
  };

  $scope.$on('forms.doctors.employed-by.didRemoveColleague', function(e, colleague) {
    ctrl.colleaguesQueuedForRemoval.push(colleague);
  });

  ctrl.saveAndSubmit = DoctorCredentialingSections.saveAndSubmit.bind(this);
  ctrl.saveForLater = DoctorCredentialingSections.saveForLater.bind(this);
}]);
