'use strict';

angular.module('header', ['vcp.api', 'nav-user.dir'])

.controller('HeaderCtrl', ['$scope', 'CurrentUser', function($scope, CurrentUser) {
  var ctrl = this;

  ctrl.isNavbarCollapsed = true;

  CurrentUser.then(function(currentUser) {
    ctrl.currentUser = currentUser;
    ctrl.isLoaded = true;
  });

  $scope.$on('auth.login', function(e, user) {
    ctrl.currentUser = user;
  });

  $scope.$on('auth.logout', function(e, user) {
    ctrl.currentUser = user;
  });
}]);
