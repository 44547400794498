'use strict';

angular.module('doctors.personal-information.ctrl', ['forms.doctors.personal-information.dir', 'section-status.dir'])

.controller('DoctorPersonalInformationCtrl', ['$q', 'Restangular', 'DoctorCredentialingSections', 'CurrentDoctor', function($q, Restangular, DoctorCredentialingSections, CurrentDoctor) {
  var ctrl = this;

  Restangular.one('doctor-credentialing-sections', 1).get().then(function(section) {
    ctrl.section = section;
  });

  CurrentDoctor.then(function(doctor) {
    ctrl.isLoaded = false;
    ctrl.doctor = doctor;
    $q.all([
      ctrl.doctor.one('status', 1).get(),
      ctrl.doctor.getList('types')
    ]).then(function(data) {
      ctrl.status = data[0];
      ctrl.doctorTypes = data[1].map(function(doctorType) {
        return doctorType.id;
      });
      ctrl.isLoaded = true;
    });
  });

  ctrl.save = function() {
    ctrl.errors = null;
    return $q.all([
      ctrl.doctor.patch(),
      ctrl.doctor.one('types').post(null, ctrl.doctorTypes)
    ]).catch(function(res) {
      return $q.reject(res.data.errors);
    });
  };

  ctrl.saveAndSubmit = DoctorCredentialingSections.saveAndSubmit.bind(this);
  ctrl.saveForLater = DoctorCredentialingSections.saveForLater.bind(this);
}]);
