'use strict';

angular.module('sign-in.ctrl', ['vcp.api'])

.controller('SignInCtrl', ['Auth', function(Auth) {
  var ctrl = this;

  ctrl.isWaiting = false;
  ctrl.alerts = [];

  ctrl.closeAlert = function(index) {
    ctrl.alerts.splice(index, 1);
  };

  ctrl.submit = function() {
    ctrl.isWaiting = true;
    ctrl.alerts = [];
    Auth.login(ctrl.values).then(Auth.redirect, function(err) {
      if (err) {
        ctrl.alerts.push({
          type: 'danger',
          msg: err.message
        });
      }
    }).finally(function() {
      ctrl.isWaiting = false;
    });
  };
}])

.config(['$locationProvider', '$routeProvider', function($locationProvider, $routeProvider) {
  $routeProvider
    .when('/sign-in', {
      templateUrl: '/sign-in/sign-in.html'
    });
}]);
