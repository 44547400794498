'use strict';

angular.module('reset-password.ctrl', ['vcp.api'])

.controller('ResetPasswordCtrl', ['$scope', '$routeParams', 'Auth', function($scope, $routeParams, Auth) {
  var ctrl = this;

  ctrl.isTokenInvalid = false;

  Auth.verifyPasswordResetToken($routeParams.token).then(function(tokenInfo) {
    ctrl.tokenInfo = tokenInfo;
  }, function() {
    ctrl.isTokenInvalid = true;
  }).finally(function() {
    ctrl.isLoaded = true;
  });

  $scope.$on('forms.reset-password.onDidResetPassword', function(event, password) {
    Auth.login({
      email: ctrl.tokenInfo.email,
      password: password
    }).then(Auth.redirect);
  });
}])

.config(['$locationProvider', '$routeProvider', function($locationProvider, $routeProvider) {
  $routeProvider
    .when('/reset-password/:token', {
      templateUrl: '/reset-password/reset-password.html'
    });
}]);
