'use strict';

angular.module('forms.doctors.professional-affiliations.dir', [])

.directive('formDoctorProfessionalAffiliations', [function() {
  return {
    restrict: 'E',
    scope: {
      affiliations: '=',
      affiliationTypes: '=',
      errors: '='
    },
    replace: true,
    templateUrl: '/forms/doctors/professional-affiliations/professional-affiliations.html',
    controller: ['$scope', function($scope) {
      var ctrl = this;

      ctrl.addAffiliation = function(type) {
        var newAffiliation = {
          affiliation_type_id: type.id
        };
        ctrl.affiliations.push(newAffiliation);
        ctrl.groupedAffiliations[type.id].push(newAffiliation);
      };

      ctrl.removeAffiliation = function(type, affiliation) {
        ctrl.affiliations.splice(ctrl.affiliations.indexOf(affiliation), 1);
        ctrl.groupedAffiliations[type.id].splice(ctrl.groupedAffiliations[type.id].indexOf(affiliation), 1);
        $scope.$emit('forms.doctors.professional-affiliations.didRemoveAffiliation', affiliation);
      };

      $scope.$watch(function () { return ctrl.affiliations; }, refreshAffiliations);
      $scope.$watch(function () { return ctrl.affiliationTypes; }, refreshAffiliations);

      function refreshAffiliations() {
        if (!ctrl.affiliations || !ctrl.affiliationTypes) return;

        ctrl.groupedAffiliations = ctrl.affiliations.reduce(function(affiliations, affiliation) {
          if (!affiliations[affiliation.affiliation_type_id]) {
            affiliations[affiliation.affiliation_type_id] = [];
          }
          affiliations[affiliation.affiliation_type_id].push(affiliation);
          return affiliations;
        }, {});

        ctrl.affiliationTypes.forEach(function(type) {
          if (!ctrl.groupedAffiliations[type.id]) {
            ctrl.groupedAffiliations[type.id] = [];
            if (!type.dynamic) {
              ctrl.addAffiliation(type);
            }
          }
        });
      }
    }],
    controllerAs: 'ctrl',
    bindToController: true
  };
}]);
