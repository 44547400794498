'use strict';

angular.module('doctors.vcd-provider-agreement.ctrl', ['forms.doctors.vcd-provider-agreement.dir'])

.controller('DoctorVcdProviderAgreementCtrl', ['$q', 'DoctorCredentialingSections', 'CurrentDoctor', 'Restangular', function($q, DoctorCredentialingSections, CurrentDoctor, Restangular) {
  var ctrl = this;

  var sectionId = 24; // section id for vcd-provider-agreement is twenty-four
  var signatureSlug = 'vcd-provider-agreement'; // slug for signature type is 'vcd-provider-agreement'

  Restangular.one('doctor-credentialing-sections', 24).get().then(function(section) {
    ctrl.section = section;
  });

  CurrentDoctor.then(function(doctor) {
    ctrl.doctor = doctor;
    return $q.all([
      doctor.one('status', sectionId).get(),
      doctor.getList('signatures', { type: signatureSlug })
    ]);
  }).then(function(data) {
    ctrl.status = data[0];
    ctrl.signature = data[1].shift() || {};
    ctrl.panel = 4; // magic number for VCD panel
    ctrl.isLoaded = true;
  });

  ctrl.save = function() {
    ctrl.errors = null;
    return $q.all([
      ctrl.doctor.all('signatures').post({
        type: signatureSlug,
        accepted: ctrl.signature.accepted,
        authorization: ctrl.signature.authorization
      })
    ]).catch(function(res) {
      return $q.reject(res.data.errors);
    });
  };

  ctrl.saveAndSubmit = DoctorCredentialingSections.saveAndSubmit.bind(this);
  ctrl.saveForLater = DoctorCredentialingSections.saveForLater.bind(this);
}]);
