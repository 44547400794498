'use strict';

angular.module('doctors.liability-insurance.ctrl', ['forms.doctors.liability-insurance.dir'])

.controller('DoctorLiabilityInsuranceCtrl', ['$q', 'DoctorCredentialingSections', 'CurrentDoctor', 'Restangular', function($q, DoctorCredentialingSections, CurrentDoctor, Restangular) {
  var ctrl = this;

  Restangular.one('doctor-credentialing-sections', 3).get().then(function(section) {
    ctrl.section = section;
  });

  CurrentDoctor.then(function(doctor) {
    ctrl.doctor = doctor;
    return $q.all([
      doctor.one('status', 3).get(),
      doctor.one('insurance').get()
    ]);
  }).then(function(data) {
    ctrl.status = data[0];
    ctrl.insurance = data[1];
    ctrl.isLoaded = true;
  });

  ctrl.save = function() {
    ctrl.errors = null;
    return ctrl.insurance.patch().catch(function(res) {
      return $q.reject(res.data.errors);
    });
  };

  ctrl.saveAndSubmit = DoctorCredentialingSections.saveAndSubmit.bind(this);
  ctrl.saveForLater = DoctorCredentialingSections.saveForLater.bind(this);
}]);
