'use strict';

angular.module('forms.doctors.additional-practice.dir', [])

.directive('formDoctorAdditionalPractice', [function() {
  return {
    restrict: 'E',
    scope: {
      readonly: '=',
      practice: '=',
      errors: '=',
      disableDeletion: '=',
      removePractice: '&',
      formId: '@'
    },
    replace: true,
    templateUrl: '/forms/doctors/additional-practice/additional-practice.html',
    controller: ['$scope', function($scope) {
      var ctrl = this;
      ctrl.effectiveDateOpen = false;

      ctrl.toggleEffectiveDateOpen = function(){ ctrl.effectiveDateOpen = !ctrl.effectiveDateOpen; };
    }],
    controllerAs: 'ctrl',
    bindToController: true
  };
}]);
