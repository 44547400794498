angular.module('doctors', [
  'doctors.ctrl',
  'forms.sign-in.dir',
  'forms.sign-up.dir',
  'doctors.welcome.ctrl',
  'doctors.credentialing.ctrl',
  'doctors.ipa-selection.ctrl',
  'doctors.additional-practices.ctrl',
  'doctors.affirmation-of-supplied-information.ctrl',
  'doctors.authorization-and-release.ctrl',
  'doctors.documentation.ctrl',
  'doctors.educational-verification.ctrl',
  'doctors.employed-by.ctrl',
  'doctors.employees.ctrl',
  'doctors.languages.ctrl',
  'doctors.liability-insurance.ctrl',
  'doctors.licensing.ctrl',
  'doctors.panel-applications.ctrl',
  'doctors.participation-fees.ctrl',
  'doctors.partners-and-associates.ctrl',
  'doctors.personal-information.ctrl',
  'doctors.practice-information.ctrl',
  'doctors.professional-affiliations.ctrl',
  'doctors.professional-questions.ctrl',
  'doctors.provider-panels.ctrl',
  'doctors.references.ctrl',
  'doctors.requirements-for-participation.ctrl',
  'doctors.single-signature-provider-acknowledgment.ctrl',
  'doctors.vision-care-direct.ctrl',
  'doctors.vcd-provider-agreement.ctrl',
  'doctors.work-history.ctrl'
]);
