'use strict';

angular.module('contact-us.ctrl', ['vcp.api'])

.controller('ContactUsCtrl', ['Restangular', function(Restangular) {
  var ctrl = this;

  ctrl.submit = function() {
    ctrl.isWaiting = true;
    Restangular.one('contact').customPOST(ctrl.values).then(function(res) {
      ctrl.alerts = [{
		  msg: 'Your message was sent.',
		  type: 'success'
	  }];
	  ctrl.values = {};
    }, function(res) {
      ctrl.errors = res.data.errors;
    }).finally(function() {
      ctrl.isWaiting = false;
    });
  };
}]);
