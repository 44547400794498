'use strict';

angular.module('forms.doctors.work-history.dir', [])

.directive('formDoctorWorkHistory', [function() {
  return {
    restrict: 'E',
    scope: {
      workHistory: '=',
      errors: '='
    },
    replace: true,
    templateUrl: '/forms/doctors/work-history/work-history.html',
    controller: ['$scope', function($scope) {
      var ctrl = this;

      ctrl.addHistory = function() {
        ctrl.workHistory.push({});
      };

      ctrl.removeHistory = function(history) {
        ctrl.workHistory.splice(ctrl.workHistory.indexOf(history), 1);
        $scope.$emit('forms.doctors.work-history.didRemoveWorkHistory', history);
      };
    }],
    controllerAs: 'ctrl',
    bindToController: true
  };
}]);
