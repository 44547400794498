'use strict';

angular.module('forms.doctors.personal-information.dir', ['vcp.api', 'select-state.dir', 'checklist-model'])

.directive('formDoctorPersonalInformation', ['Restangular', function(Restangular) {
  return {
    restrict: 'E',
    scope: {
      doctor: '=',
      doctorTypes: '=',
      errors: '='
    },
    replace: true,
    templateUrl: '/forms/doctors/personal-information/personal-information.html',
    controller: ['$scope', function($scope) {
      var ctrl = this;

      ctrl.ethnicityOptions = [
        'Caucasian',
        'Hispanic',
        'African American'
      ];

      $scope.$watch(function() { return ctrl.doctor; }, function() {
        if (!ctrl.doctor) return;

        if (ctrl.ethnicityOptions.indexOf(ctrl.doctor.ethnicity) !== -1) {
          ctrl.ethnicity = ctrl.doctor.ethnicity;
        } else {
          ctrl.ethnicity = 'Other';
          ctrl.ethnicityOther = ctrl.doctor.ethnicity;
        }
      });

      $scope.$watch(function() { return ctrl.ethnicityOther; }, function() {
        if (!ctrl.doctor) return;

        if (ctrl.ethnicity == 'Other') {
          ctrl.doctor.ethnicity = ctrl.ethnicityOther;
        }
      });

      $scope.$watch(function() { return ctrl.ethnicity; }, function() {
        if (!ctrl.doctor) return;

        if (ctrl.ethnicity == 'Other') {
          ctrl.doctor.ethnicity = ctrl.ethnicityOther;
        } else {
          ctrl.doctor.ethnicity = ctrl.ethnicity;
        }
      });
    }],
    link: function(scope) {
      Restangular.all('doctor-types').getList().then(function(types) {
        scope.availableDoctorTypes = types;
      });
    },
    controllerAs: 'ctrl',
    bindToController: true
  };
}]);
