'use strict';

angular.module('forms.reset-password.dir', ['reset-password.ctrl'])

.directive('formResetPassword', [function() {
  return {
    restrict: 'E',
    scope: {
      token: '='
    },
    replace: true,
    templateUrl: '/forms/reset-password/reset-password.html',
    controller: ['$rootScope', 'Auth', function($rootScope, Auth) {
      var ctrl = this;

      ctrl.alerts = [];
      ctrl.closeAlert = function(index) {
        ctrl.alerts.splice(index, 1);
      };

      ctrl.submit = function(form) {
        ctrl.isWaiting = true;
        ctrl.alerts = [];
        Auth.resetPassword(ctrl.token, ctrl.values.password, ctrl.values.password_confirm).then(function() {
          $rootScope.$broadcast('forms.reset-password.onDidResetPassword', ctrl.values.password);
        }, function(err) {
          if (err) {
            ctrl.alerts.push({
              type: 'danger',
              msg: err.message
            });
            ctrl.errors = err.errors;
          }
        }).finally(function() {
          ctrl.isWaiting = false;
        });
      };
    }],
    controllerAs: 'resetPasswordCtrl',
    bindToController: true
  };
}]);
