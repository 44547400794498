'use strict';

angular.module('forms.doctors.licensing.dir', ['select-state.dir'])

.directive('formDoctorLicensing', [function() {
  return {
    restrict: 'E',
    scope: {
      doctor: '=',
      dea: '=',
      boardCertification: '=',
      stateLicenses: '=',
      errors: '='
    },
    replace: true,
    templateUrl: '/forms/doctors/licensing/licensing.html',
    controller: ['$scope', function($scope) {
      var ctrl = this;

      ctrl.addStateLicense = function() {
        ctrl.stateLicenses.push({});
      };

      ctrl.removeStateLicense = function(stateLicense) {
        ctrl.stateLicenses.splice(ctrl.stateLicenses.indexOf(stateLicense), 1);

        if (stateLicense.is_primary && ctrl.stateLicenses.length == 1) {
          ctrl.stateLicenses[0].is_primary = true;
        }

        $scope.$emit('forms.doctors.licensing.didRemoveStateLicense', stateLicense);
      };

      ctrl.setPrimaryLicense = function(license) {
        ctrl.stateLicenses.forEach(function(license) {
          license.is_primary = false;
        });
        license.is_primary = true;
      };
    }],
    controllerAs: 'ctrl',
    bindToController: true
  };
}]);
