'use strict';

angular.module('forms.my-account.dir', [])

.directive('formMyAccount', [function() {
  return {
    restrict: 'E',
    scope: false,
    replace: true,
    templateUrl: '/forms/my-account/my-account.html',
    controller: ['Auth', function(Auth) {
      var ctrl = this;

      Auth.whoAmI().then(function(user) {
        ctrl.user = user;
        ctrl.isLoaded = true;
      });

      ctrl.submit = function() {
        if (ctrl.isWaiting) {
          return;
        }

        ctrl.isWaiting = true;
        ctrl.alerts = null;
        ctrl.errors = null;

        ctrl.user.patch().then(function(user) {
          ctrl.user = user;
          ctrl.isChangingPassword = false;
          ctrl.alerts = [{
            type: 'success',
            message: 'Account information has been saved.'
          }];
        }, function(res) {
          ctrl.errors = res.data.errors;
          ctrl.alerts = [{
            type: 'danger',
            message: 'Please check your account information.'
          }];
        }).finally(function() {
          ctrl.isWaiting = false;
        });
      };
    }],
    controllerAs: 'myAccountCtrl',
    bindToController: true
  };
}]);
