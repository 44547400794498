'use strict';

angular.module('nav-user.dir', ['vcp.api'])

.directive('navUser', [function() {
  return {
    restrict: 'E',
    scope: {
      user: '='
    },
    replace: true,
    templateUrl: '/nav-user/nav-user.html',
    controller: ['$scope', 'Auth', function($scope, Auth) {
      var ctrl = this;

      ctrl.logout = function() {
        Auth.logout();
      };

      $scope.$watch(function() {
        return ctrl.user;
      }, function() {
        if (!ctrl.user) {
          return;
        }
        if (ctrl.user.roles.admin || ctrl.user.roles.ipa_admin) {
          ctrl.displayName = ctrl.user.name || ctrl.user.email;
        } else if (ctrl.user.roles.doctor) {
          ctrl.displayName = ctrl.user.first_name + ' ' + ctrl.user.last_name;
        }
      });
    }],
    controllerAs: 'navUser',
    bindToController: true
  };
}]);
