'use strict';

angular.module('forms.doctors.professional-questions.dir', ['forms.doctors.question.dir', 'forms.electronic-signature'])

.directive('formDoctorProfessionalQuestions', [function() {
  return {
    restrict: 'E',
    scope: {
      doctor: '=',
      questions: '=',
      signature: '=',
      errors: '='
    },
    replace: true,
    templateUrl: '/forms/doctors/professional-questions/professional-questions.html',
    controller: [function() {}],
    controllerAs: 'ctrl',
    bindToController: true
  };
}]);
