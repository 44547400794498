'use strict';

angular.module('doctors.practice-information.ctrl', ['forms.doctors.practice-information.dir'])

.controller('DoctorPracticeInformationCtrl', ['$q', 'CurrentDoctor', 'Restangular', 'DoctorCredentialingSections', function($q, CurrentDoctor, Restangular, DoctorCredentialingSections) {
  var ctrl = this;

  Restangular.one('doctor-credentialing-sections', 8).get().then(function(section) {
    ctrl.section = section;
  });

  CurrentDoctor.then(function(doctor) {
    ctrl.doctor = doctor;
    ctrl.readonly = false;
    return $q.all([
      doctor.one('status', 8).get(),
      doctor.one('practice').get()
    ]);
  }).then(function(data) {
    ctrl.status = data[0];
    ctrl.practice = data[1];

    ctrl.isLoaded = true;
  });

  ctrl.save = function() {
    ctrl.errors = null;

    if (!ctrl.practice.has_different_claims_address) {
      ctrl.practice.claims_address = '';
      ctrl.practice.claims_city = '';
      ctrl.practice.claims_state = '';
      ctrl.practice.claims_zip = '';
    }

    if (!ctrl.practice.has_hospital_privileges) {
      ctrl.practice.hospital_name = '';
      ctrl.practice.hospital_address = '';
      ctrl.practice.hospital_phone = '';
    }

    if (!ctrl.practice.offers_telehealth) {
      ctrl.practice.telehealth_mode_mobile_health = false;
      ctrl.practice.telehealth_mode_real_time = false;
      ctrl.practice.telehealth_mode_remote_patient_monitoring = false;
      ctrl.practice.telehealth_mode_store_and_forward = false;
      ctrl.practice.telehealth_services = '';
    }

    if (!ctrl.practice.mailing_different_from_claims_address) {
      ctrl.practice.mailing_address = '';
      ctrl.practice.mailing_city = '';
      ctrl.practice.mailing_state = '';
      ctrl.practice.mailing_zip = '';
      ctrl.practice.mailing_phone = '';
      ctrl.practice.mailing_fax = '';
    }

    return $q.all([
      ctrl.practice.patch()
    ]).catch(function(res) {
      return $q.reject(res.data.errors);
    });
  };

  ctrl.saveAndSubmit = DoctorCredentialingSections.saveAndSubmit.bind(this);
  ctrl.saveForLater = DoctorCredentialingSections.saveForLater.bind(this);
}]);
