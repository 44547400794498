'use strict';

angular.module('forms.doctors.practice-information.dir', [
    'fields.date-field.date-field.dir',
    'forms.validation.errors.dir'
])

.directive('formDoctorPracticeInformation', [function() {
  return {
    restrict: 'E',
    scope: {
      practice: '=',
      errors: '=',
      readonly: '=',
      formId: '='
    },
    replace: true,
    templateUrl: '/forms/doctors/practice-information/practice-information.html',
    controller: [function() {}],
    controllerAs: 'ctrl',
    bindToController: true
  };
}]);
