'use strict';

angular.module('forms.sign-in.dir', ['sign-in.ctrl'])

.directive('formSignIn', [function() {
  return {
    restrict: 'E',
    scope: false,
    replace: true,
    templateUrl: '/forms/sign-in/sign-in.html',
    controller: 'SignInCtrl',
    controllerAs: 'signInCtrl',
    bindToController: true
  };
}]);
