'use strict';

angular.module('doctors.welcome.ctrl', ['vcp.api'])

.controller('DoctorWelcomeCtrl', ['Auth', 'CurrentDoctor', 'Restangular', function(Auth, CurrentDoctor, Restangular) {
  var ctrl = this;

  CurrentDoctor.then(function(doctor) {
    ctrl.doctor = doctor;
    if (doctor.ipa_id) {
      return Restangular.one('ipas', ctrl.doctor.ipa_id).get().then(function(ipa) {
        ctrl.ipa = ipa;
      });
    }
  }).then(function() {
    ctrl.isLoaded = true;
  });
}]);
