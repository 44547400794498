'use strict';

angular.module('forms.doctors.question.dir', [])

.directive('doctorQuestion', [function() {
  return {
    restrict: 'E',
    scope: {
      question: '=',
      answer: '=ngModel',
      errors: '='
    },
    replace: true,
    templateUrl: '/forms/doctors/question/question.html',
    controller: ['$scope', function ($scope) {
      $scope.showDetails = false;
      $scope.$watch('answer.response', function() {
        if ($scope.answer.response == 'Y') {
          $scope.showDetails = !!$scope.question.details_required_on_yes;
        } else if($scope.answer.response == 'N') {
          $scope.showDetails = !!$scope.question.details_required_on_no;
        }
      });
    }]
  };
}]);
