'use strict';

angular.module('forms.forgot-password.dir', ['forgot-password.ctrl'])

.directive('formForgotPassword', [function() {
  return {
    restrict: 'E',
    scope: false,
    replace: true,
    templateUrl: '/forms/forgot-password/forgot-password.html',
    controller: 'ForgotPasswordCtrl',
    controllerAs: 'forgotPasswordCtrl',
    bindToController: true
  };
}]);
