'use strict';

angular.module('forms.doctors.partners-and-associates.dir', [])

.directive('formDoctorPartnersAndAssociates', [function() {
  return {
    restrict: 'E',
    scope: {
      colleagues: '=',
      errors: '='
    },
    replace: true,
    templateUrl: '/forms/doctors/partners-and-associates/partners-and-associates.html',
    controller: ['$scope', function($scope) {
      var ctrl = this;

      ctrl.addColleague = function() {
        ctrl.colleagues.push({
          colleague_type_id: 1
        });
      };

      ctrl.removeColleague = function(colleague) {
        ctrl.colleagues.splice(ctrl.colleagues.indexOf(colleague), 1);
        $scope.$emit('forms.doctors.partners-and-associates.didRemoveColleague', colleague);
      };
    }],
    controllerAs: 'ctrl',
    bindToController: true
  };
}]);
