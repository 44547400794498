'use strict';

angular.module('forms.doctors.documentation.dir', ['angularFileUpload'])

.directive('formDoctorDocumentation', [function() {
  return {
    restrict: 'E',
    scope: {
      doctor: '=',
      documentTypes: '=',
      errors: '='
    },
    replace: true,
    templateUrl: '/forms/doctors/documentation/documentation.html',
    controller: ['$scope', 'FileUploader', function($scope, FileUploader) {
      var ctrl = this;
      ctrl.uploadableDocuments = [];

      ctrl.refreshUploadableDocuments = function() {
        if (!ctrl.documentTypes || !ctrl.doctor) {
          return;
        }

        ctrl.uploadableDocuments = ctrl.documentTypes.map(function(documentType) {
          var uploadPath = '/api/v1/doctors/' + ctrl.doctor.id + '/documents';
          if (documentType.uploader) {
            documentType.uploader.url = uploadPath;
            return;
          }

          documentType.uploader = new FileUploader({
            url: uploadPath,
            alias: 'document',
            autoUpload: true,
            removeAfterUpload: true,
            formData: [{ 'doctor_document_type_id': documentType.id }]
          });

          documentType.uploader.onBeforeUploadItem = function() {
            documentType.alert = {
              msg: 'Uploading',
              type: 'warning'
            };
          };

          documentType.uploader.onSuccessItem = function() {
            documentType.alert = {
              msg: 'Upload Complete',
              type: 'success'
            };
          };
        });
      };

      $scope.$watch(function() {
        return ctrl.doctor;
      }, ctrl.refreshUploadableDocuments);

      $scope.$watch(function() {
        return ctrl.documentTypes;
      }, ctrl.refreshUploadableDocuments);
    }],
    controllerAs: 'ctrl',
    bindToController: true
  };
}]);
