'use strict';

var app = angular.module('vcpApp', [
  'ngRoute',
  'app.templates',
  'app.website'
]);

angular.module('app.website', [
  'ui.bootstrap',
  'header',
  'footer',
  'welcome.ctrl',
  'contact-us.ctrl',
  'forms.my-account.dir',
  'forms.sign-in.dir',
  'forms.forgot-password.dir',
  'forms.reset-password.dir',
  'doctors'
]);

app.config(['$locationProvider', '$routeProvider', '$httpProvider', function($locationProvider, $routeProvider, $httpProvider) {
  if (!$httpProvider.defaults.headers.get) {
    $httpProvider.defaults.headers.common = {};
  }
  $httpProvider.defaults.headers.common["Cache-Control"] = "no-cache";
  $httpProvider.defaults.headers.common.Pragma = "no-cache";
  $httpProvider.defaults.headers.common["If-Modified-Since"] = "0";

  $routeProvider
    .when('/', {
      templateUrl: '/welcome/welcome.html',
      cms: true
    })
    .when('/about', {
      templateUrl: '/cms/cms.html',
      cms: true
    })
    .when('/contact', {
      templateUrl: '/contact-us/contact-us.html',
      cms: true
    })
    .when('/policies/privacy', {
      templateUrl: '/cms/cms.html',
      cms: true
    })
    .when('/policies/refund', {
      templateUrl: '/cms/cms.html',
      cms: true
    })
    .when('/my-account', {
      templateUrl: '/my-account/my-account.html'
    });
}])

.run(['$rootScope', '$sce', '$location', '$window', 'Restangular', function($rootScope, $sce, $location, $window, Restangular) {
  $rootScope.$on('$routeChangeStart', function(event, current, previous) {
    $rootScope.cms = null;
    if (current.hasOwnProperty('$$route') && current.$$route.cms) {
      var path = current.$$route.originalPath;
      path = path.substr(1);
      Restangular.one('content').get({ path: path }).then(function(cms) {
        cms.source = $sce.trustAsHtml(cms.source);
        $rootScope.cms = cms;
      });
    }
  });
  $rootScope.$on('$routeChangeSuccess', function() {
    if (ga) {
      ga('send', 'pageview', $location.path());
    }
  });
  $rootScope.$on('auth.logout', function() {
    $location.path('/sign-in');
  });
  $rootScope.$on('auth.login', function(event, user) {
    var roles = user.roles;
    if (roles.admin || roles.ipa_admin || roles.auditor || roles.editor) {
      $window.location.href = '/admin';
    } else if (roles.doctor) {
      $location.path('/doctors/welcome');
    }
  });
}]);
