'use strict';

angular.module('forms.doctors.liability-insurance.dir', ['select-state.dir', 'ng-currency'])

.directive('formDoctorLiabilityInsurance', [function() {
  return {
    restrict: 'E',
    scope: {
      liabilityInsurance: '=',
      errors: '='
    },
    replace: true,
    templateUrl: '/forms/doctors/liability-insurance/liability-insurance.html',
    controller: [function() {}],
    controllerAs: 'ctrl',
    bindToController: true
  };
}]);
