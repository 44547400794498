'use strict';

angular.module('doctors.additional-practices.ctrl', ['forms.doctors.additional-practices.dir'])

.controller('DoctorAdditionalPracticesCtrl', ['$q', '$scope', 'CurrentDoctor', 'Restangular', 'DoctorCredentialingSections', function($q, $scope, CurrentDoctor, Restangular, DoctorCredentialingSections) {
  var ctrl = this;

  ctrl.practicesQueuedForRemoval = [];

  Restangular.one('doctor-credentialing-sections', 23).get().then(function(section) {
    ctrl.section = section;
  });

  CurrentDoctor.then(function(doctor) {
    ctrl.doctor = doctor;
    return $q.all([
      doctor.one('status', 23).get(),
      doctor.all('additional-practices').getList()
    ]);
  }).then(function(data) {
    ctrl.status = data[0];
    ctrl.practices = data[1];
    ctrl.isLoaded = true;
  });

  ctrl.save = function() {
    ctrl.errors = null;
    var promises = [];

    ctrl.practices.forEach(function(practice) {
      promises.push(practice.id ? practice.patch() : ctrl.practices.post(practice));
    });

    ctrl.practicesQueuedForRemoval.forEach(function(practice) {
      promises.push(practice.remove());
    });

    return $q.all(promises).catch(function(res) {
      return $q.reject(res.data.errors);
    });
  };

  $scope.$on('forms.doctors.additional-practices.didRemovePractice', function(e, practice) {
    if (!practice.id) return;
    ctrl.practicesQueuedForRemoval.push(practice);
  });

  ctrl.saveAndSubmit = DoctorCredentialingSections.saveAndSubmit.bind(this);
  ctrl.saveForLater = DoctorCredentialingSections.saveForLater.bind(this);
}]);
